<template>
    <div class="zt-page-content">
        <ItemApply></ItemApply>
    </div>
</template>

<script>
    // import { getCompInfoById } from '@/service/company';
    import ItemApply from './ItemApply.vue';
    export default {
        components: {
            ItemApply
        },
        data() {
            return {
                
            }
        },
        created() {
            
        },
        methods: {
            
        }
    }
</script>
<style scoped>
    
</style>