<template>
    <div v-if="itemInfo && itemInfo.stat!=2 || itemInfo && itemInfo.stat==2&&this.itemInfo.wx.length==0" :class="{'active':itemInfo.stat==2&&this.itemInfo.wx.length==0}" class="content">
        <div v-if="itemInfo && itemInfo.stat!=2" class="zt-block" style="height:calc(100% - 40px)">
            <div class="apply flex flex-center" ref="load" style="width:100%;height:100%">
                <div>
                    <el-button v-if="itemInfo && !itemInfo.id" @click="apply()" size="small" type="primary">申请总代理</el-button>
                    <el-button v-if="itemInfo && itemInfo.stat==3" @click="apply()" size="small" type="primary">重新申请</el-button>
                    <div class="apply-text">
                        <div v-if="!itemInfo">您还尚未开通分销店铺</div>
                        <div v-if="itemInfo && itemInfo.stat==3">您的申请不通过</div>
                        <div v-if="itemInfo && itemInfo.stat==1" style="font-size:50px;color:#CCCCCC;margin-bottom:40px;">申请中</div>
                        <!-- <div v-if="itemInfo && itemInfo.stat==2" style="font-size:50px;color:#CCCCCC;margin-bottom:40px;">已通过</div> -->
                    </div>
                    <el-popover
                        placement="top-start"
                        title=""
                        width="400"
                        trigger="hover"
                        content="您可以为自己的产品通过与其他销售商合作，让这些销售商帮助销售该产品，从而建立起产品的零售渠道。这些销售商就属于您的分销商，每销售一件商品，您和您的分销商都能获得一定的利润。">
                        <a slot="reference">什么是分销店铺？</a>
                    </el-popover>
                </div>
                <div class="apply-bottom">如有疑问请联系客服人员，手机号：19970936393</div>

            </div>

        </div>
        <el-dialog width="500px" :title="popTitle" :visible.sync="popVisible" :destroy-on-close="true" :append-to-body="true">
            <el-form v-if="popTitle=='申请总代理'" :model="ruleForm" :rules="rules" ref="ruleForm" class="ruleFrom">
                <el-form-item prop="store_name">
                    <el-input placeholder="店铺名称" v-model="ruleForm.store_name"></el-input>
                </el-form-item>
                <el-form-item prop="contact_name">
                    <el-input placeholder="联系人" v-model="ruleForm.contact_name"></el-input>
                </el-form-item>
                <el-form-item prop="contact_mobile">
                    <el-input placeholder="请输入手机号" v-model="ruleForm.contact_mobile" :maxlength="11"></el-input>
                </el-form-item>
                <el-form-item prop="captcha">
                    <el-input v-model="ruleForm.captcha" placeholder="请输入图形验证码" class="reg-create-captcha-ipt">
                        <div slot="append" v-bind:style="{background:captchaBg}" class="captcha-img"
                            @click="refreshCaptcha">
                        </div>
                    </el-input>
                </el-form-item>
                <el-form-item style="margin-bottom:20px;" prop="code">
                    <el-input placeholder="请输入短信验证码" v-model="ruleForm.code">
                        <template slot="append">
                            <div v-if="invsec<=0" @click="getMobileCode" style="cursor: pointer;">获取验证码</div>
                            <div v-if="invsec>0">{{invsec}}秒后重试</div>
                        </template>
                    </el-input>
                </el-form-item>
                <el-form-item prop="n_store_limit">
                    <el-input placeholder="可申请账号数" v-model="ruleForm.n_store_limit" type="number"></el-input>
                </el-form-item>
                <el-form-item prop="n_product_limit">
                    <el-select v-model="ruleForm.n_product_limit" style="width:100%" placeholder="请选择可上传商品数">
                        <el-option
                        v-for="item in nplOptions"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <div class="flex flex-justify-around" style="padding:20px 0;">
                    <el-button @click="hide()" style="width:90px;" type="primary" plain>取消</el-button>
                    <el-button @click="submitForm('ruleForm')" style="width:90px;" type="primary">申请</el-button>
                </div>
            </el-form>
            <div v-if="popTitle=='绑定微信账号'" class="invite">
                <div class="wx_code flex flex-center">
                    <img style="position:relative;top:-35px
                            transform:scale(0.6);
                            -ms-transform:scale(0.6);
                            -moz-transform:scale(0.6);
                            -webkit-transform:scale(0.6);
                            -o-transform:scale(0.6);"
                    :src="wx_code"/>
                </div>
                <div class="wx_text">
                    <div>扫码进入小程序，将小程序链接分享给代理人</div>
                </div>
                <div class="flex flex-justify-around" style="padding: 30px 140px 10px;">
                    <el-button @click="popVisible=false" style="width:76px;" size="small" type="primary" plain>取消</el-button>
                    <el-button @click="popVisible=false" style="width:76px;" size="small" type="primary">确定</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import { getCaptCha, sendMobileCode } from '@/service/common';
    import { phoneReg } from "@/utils";
    import { applyGeneralAgent, getGeneralAgent, getAgentWxqr } from '@/service/sharer';
    export default {
        props: [''],
        data() {
            let checkPhone = (rule, value, callback) => {
                if (!value) {
                return callback(new Error('请输入手机号码'));
                } else {
                    const reg = phoneReg;
                    if (reg.test(value)) {
                        callback();
                    } else {
                        return callback(new Error('请输入正确的手机号码'));
                    }
                }
            };
            return {
                popTitle:null,
                popVisible:false,
                itemInfo:null,
                ruleForm:{
                    store_name: null,
                    contact_name: null,
                    contact_mobile: null,
                    n_store_limit: null,
                    n_product_limit: null,
                    code: null,
                    captcha: null,
                    captcha_token: null,
                },
                rules: {
                    store_name: [{ required: true, message: '请输入店铺名称', trigger: 'blur' }],
                    contact_name: [{ required: true, message: '请输入联系人名称', trigger: 'blur' }],
                    contact_mobile: [{validator: checkPhone, trigger: 'blur'}],
                    captcha: [{ required: true, message: '请输入图形验证码', trigger: 'blur' }],
                    code: [{ required: true, message: '请输入短信验证码', trigger: 'blur' }],
                    n_store_limit: [{ required: true, message: '请输入申请账号数', trigger: 'blur' }],
                    n_product_limit:[{ required: true, message: '请选择可上传商品数', trigger: 'blur' }],
                },
                nplOptions: [
                    { id: 20, name: '20' },
                    { id: 100, name: '100' },
                    { id: 200, name: '200' },
                    { id: 400, name: '200' },
                    { id: 99999, name: '不限' }
                ],
                captchaBg: null,
                captchaToken: null,
                invsec: 0,
                timer: null,
                wx_code:null,
            }
        },
        watch: {},
        created() {
            this.refreshItems();
        },
        methods: {
            refreshItems(){
                // const _loading = this.$loading({
                //     target:this.$refs.load,
                //     lock: true,
                //     text: "拼命加载中....",
                // });
                getGeneralAgent().then(rst=>{
                    this.$emit("shareState",rst);
                    // this.invite();
                    this.itemInfo = rst;
                    if (rst.stat==2 && rst.wx && rst.wx.length==0) {
                        this.invite();
                    }
                    console.log("applyInfo",this.itemInfo);
                    // _loading.close();
                }).catch(err => {
                    // _loading.close();
                    this.$message.error(err.message);
                });
            },
            invite() {
                this.popTitle = "绑定微信账号";
                this.popVisible = true;
                this.get_wx_code();
            },
            get_wx_code() {
                let obj = {
                    page_uri: "pages/index/index",
                    img_width: "140px",
                    page_param:'idx=5&sid='+this.itemInfo.id+'&pid=0',
                };
                getAgentWxqr(obj).then(rst => {
                    // console.log("1111111", rst);
                    this.wx_code = "data:image/png;base64," + rst.img;
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
            apply() {
                this.popTitle = "申请总代理";
                this.popVisible = true;
                this.refreshCaptcha();
            },
            refreshCaptcha() {
                getCaptCha().then(rsp => {
                    this.captchaBg = `url('data:image/svg+xml,${encodeURIComponent(rsp.svg)}') no-repeat center`;
                    this.captchaToken = rsp.token;
                }).catch(err => {
                    this.$message.error(err.message);
                })
            },
            getMobileCode() {
                sendMobileCode(this.ruleForm.contact_mobile,'apply_factory_agency').then(() => {
                    this.invsec = 60;
                    this.timer = setInterval(() => {
                        if (this.invsec == 0) {
                            clearInterval(this.timer);
                            return;
                        }
                        this.invsec--;
                    }, 1000);
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                })
            },
            submitForm(formName) {
                console.log(this.ruleForm);
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.ruleForm.captcha_token = this.captchaToken;
                        applyGeneralAgent(this.ruleForm).then(()=>{
                            this.hide();
                            this.refreshItems();
                            this.$message.success("提交申请成功");
                        }).catch(err=>{
                            this.$message.error(err.message);
                        });
                    } else {
                        this.refreshCaptcha();// 刷新二维码
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            hide() {
                this.popVisible = false;
            },
        }
    }
</script>
<style scoped>
    .content {
        width: 100%;
        height: 100%;
    }
    .content.active {
        width: 0;
        height: 0;
    }
    .apply {
        text-align: center;
        position: relative;
    }
    .apply-text {
        padding: 30px 0 18px 0;
    }

    .ruleFrom {
        width: 300px;
        margin: 0 auto;
    }
    .reg-create-captcha-ipt>>> .el-input-group__append {
        padding: 0 5px;
        overflow: hidden;
    }
    .captcha-img {
        height: 38px;
        background-size: 100% 100% !important;
        width: 130px;
        cursor: pointer;
    }
    .ruleFrom>>> input[type=number] {
        padding: 0 0 0 15px;
    }

    .apply-bottom {
        font-size: 14px;
        color: #808080;
        padding: 18px 0;
        position: absolute;
        bottom: 0;
    }

    .wx_code {
        width: 100%;
        height: 220px;
        overflow: hidden;
    }
    .wx_text {
        text-align: center;
        font-size: 16px;
        color: #292929;
    }
</style>